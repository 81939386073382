import React from "react";
import PropTypes from "prop-types";
import "./news.css";
import Moment from "moment";
import "../layout.css";

const Article = ({ data }) => {
  console.log("data", data);
  const {
    html,
    frontmatter: {
      date,
      title,
      image,
    },
  } = data[0].node.childMarkdownRemark;

  return (
    <>
      <span className='news__title'>{title}</span>
      <span className='news__date'>{new Moment(date).format("MMMM Do YYYY, h:mm:ss a")}</span>
      {image && (
        <img src={image} className='news__image' style={{justifyContent: "start" }} alt={title} />
      )}
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </>
  );

};

Article.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Article;